<template>
  <section class="bg-white dark:bg-gray-900">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
      <div class="mx-auto mb-8 max-w-screen-sm lg:mb-16">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Our team</h2>
        <p class="mb-4 font-light">
          For inquiries, contact us at <a href="mailto:admin@pubmatcher.com"
            class="text-blue-500 underline">admin@pubmatcher.fr</a>.
        </p>
      </div>
      <div class="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <div class="text-center text-gray-500 dark:text-gray-400">

          <h3 class="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Victor Marin
          </h3>
          <p>Co-founder</p>
          <ul class="flex justify-center mt-4 space-x-4">
            <li>
              <a href="https://github.com/victormar1" target="_blank" class=" text-gray-800 hover:text-gray-900 dark:hover:text-white
                dark:text-gray-300">
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                    clip-rule="evenodd" />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/victor-marin-033a29257/" target="_blank"
                class="text-[#000000] hover:text-gray-900 dark:hover:text-white">
                <svg class="w-6 h-6  dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                  height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd"
                    d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                    clip-rule="evenodd" />
                  <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
                </svg>
              </a>
            </li>
          </ul>

        </div>
        <div class="text-center text-gray-500 dark:text-gray-400">

          <h3 class="mb-1 text-2xl font-bold tracking-tight text-gray-800 dark:text-white">
            Louis Lebreton
          </h3>
          <p>Co-founder</p>
          <ul class="flex justify-center mt-4 space-x-4">

            <li>
              <a href="https://www.linkedin.com/in/louis-lebreton-749839197/" target="_blank"
                class="text-[#000000] hover:text-gray-900 dark:hover:text-white">
                <svg class="w-6 h-6  dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                  height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd"
                    d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                    clip-rule="evenodd" />
                  <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
                </svg>
              </a>
            </li>
          </ul>

        </div>
        <div class="text-center text-gray-500 dark:text-gray-400">

          <h3 class="mb-1 text-2xl font-bold tracking-tight text-gray-800 dark:text-white">
            Hugo Lannes
          </h3>
          <p>Developer</p>
          <ul class="flex justify-center mt-4 space-x-4">
            <li>
              <a href="https://github.com/HugoLannes" target="_blank" class=" text-gray-900 hover:text-gray-900 dark:hover:text-white
      dark:text-gray-300">
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                    clip-rule="evenodd" />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/hugo-lannes/" target="_blank"
                class="text-[#000000] hover:text-gray-900 dark:hover:text-white">
                <svg class="w-6 h-6  dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                  height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd"
                    d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                    clip-rule="evenodd" />
                  <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
                </svg>
              </a>
            </li>
          </ul>

        </div>
        <div class="text-center text-gray-500 dark:text-gray-400">

          <h3 class="mb-1 text-2xl font-bold tracking-tight text-gray-800 dark:text-white">
            Victor Dumont
          </h3>
          <p>Developer</p>
          <ul class="flex justify-center mt-4 space-x-4">
            <li>
              <a href="https://github.com/victorDumont" target="_blank" class=" text-gray-900 hover:text-gray-900 dark:hover:text-white
      dark:text-gray-300">
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                    clip-rule="evenodd" />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/victor-dumont-108183165/" target="_blank"
                class="text-[#000000] hover:text-gray-900 dark:hover:text-white">
                <svg class="w-6 h-6  dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                  height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd"
                    d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                    clip-rule="evenodd" />
                  <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
                </svg>
              </a>
            </li>
          </ul>

        </div>
      </div>
      <hr class="my-10 border-t  border-gray-150" />

      <div class=" mt-25">
        <p class="font-light  text-gray-500 sm:text-xl dark:text-gray-400">Explore and contribute to the PubMatcher
          project
          on GitHub.

        </p>
        <button onclick="window.open('https://github.com/victormar1/PubMatcher', '_blank', 'noopener,noreferrer')"
          type="button"
          class="text-white mt-3 bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 drop-shadow shadow-blue-500/50  dark:shadow-blue-800/80 font-medium rounded-xl text-sm px-5 py-2.5 text-center me-2 mb-2 ">Visit
          GitHub</button>
      </div>


    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactPage',
  data() {
    return {
      teamMembers: [
        {
          name: 'Victor Marin',
          linkedin: 'https://www.linkedin.com/in/victor-marin-033a29257/',
          github: 'https://github.com/victormar1',
        },
        {
          name: 'Hugo Lannes',
          linkedin: 'https://www.linkedin.com/in/hugo-lannes/',
          github: 'https://github.com/HugoLannes',
        },
        {
          name: 'Victor Dumont',
          linkedin: 'https://www.linkedin.com/in/victor-dumont-108183165/',
          github: 'https://github.com/victorDumont',
        },
        {
          name: 'Louis Lebreton',
          linkedin: 'https://www.linkedin.com/in/louis-lebreton-749839197/',
        },
      ],
    };
  },
};
</script>

<style scoped>
.contact-page {
  font-family: 'Arial', sans-serif;
}

.team-member {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-member:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}
</style>
