<template>
    <section class="bg-white dark:bg-gray-800  border-red-700 h-[750px] overflow-x-hidden  overflow-y-scroll  ">
        <div>

            <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
                <div class="max-w-screen-lg text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 class="mb-4 text-4xl tracking-tight font-bold text-gray-800 dark:text-white">General Information
                    </h2>
                    <p class="mb-4 font-light">This project is developed and maintained by the PubMatcher team. The
                        information provided by the project is for general informational and educational purposes only.
                    </p>
                    <p class="mb-4 font-medium">While we aim to ensure the accuracy and reliability of the data and
                        tools
                        provided, we make no representations or warranties, express or implied, about the completeness,
                        accuracy, reliability, suitability, or availability of the information for any purpose.</p>
                </div>
                <hr class="my-4 border-t  border-gray-150" />

                <div class="max-w-screen-lg text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 class="mb-4 text-4xl tracking-tight font-bold text-gray-800 dark:text-white">Liability
                    </h2>
                    <p class="mb-4 font-light">The software, tools, and services provided by this project are offered on
                        an
                        "as-is" and "as-available" basis without any guarantees or warranties of any kind. PubMatcher
                        and
                        its contributors shall not be held responsible for any errors, omissions, or
                        inaccuracies in the data or for any actions taken in reliance on the information provided by
                        this
                        project.
                    </p>
                    <p class="mb-4 font-medium">By using this project, you agree to assume full responsibility for your
                        actions and decisions based on the provided tools and data.</p>
                </div>
                <hr class="my-4 border-t  border-gray-150" />

                <div class="max-w-screen-lg text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 class="mb-4 text-4xl tracking-tight font-bold text-gray-800 dark:text-white">Third-Party
                        Services
                    </h2>
                    <p class="mb-4 font-light">This project may use third-party APIs, services, or datasets. We do not
                        control or endorse these third-party resources and are not liable for any inaccuracies, changes,
                        or issues resulting from their use.
                    </p>

                </div>
                <hr class="my-4 border-t  border-gray-150" />

                <div class="max-w-screen-lg text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 class="mb-4 text-4xl tracking-tight font-bold text-gray-800 dark:text-white">Medical Diagnosis
                    </h2>
                    <p class="mb-4 font-light">This tool is intended for informational and research purposes only and
                        should not be used as a substitute for professional medical advice, diagnosis, or treatment.
                    </p>
                    <p class="mb-4 font-medium">The
                        data and results generated by this tool are based on computational algorithms and publicly
                        available resources, and while we strive for accuracy, errors and omissions may occur.</p>
                </div>


            </div>
        </div>

    </section>
</template>

<script>
export default {
    name: 'DisclaimerPage',

};
</script>