<template>
  <header class="bg-gray-800 py-3 shadow-md min-w-screen font-noto drop-shadow">
    <div class="flex justify-between px-4 items-center">
      <div class="flex items-center">
        <router-link to="/" class="flex items-center">
          <img class="max-h-12 w-auto object-cover" src="/images/pmlogo.svg" alt="PubMatcher Logo" />
          <!-- Titre principal -->
          <div class="flex flex-row items-baseline space-x-1 ">
            <h1 class="text-5xl font-bold  text-white">PubMatcher</h1>
          </div>
          <div class=" w-24 h-10 justify-end items-end flex">
            <svg width="50" height="20" viewBox="0 0 350 163" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 81.0262C0 36.2767 36.2767 0 81.0262 0H268.099C312.848 0 349.125 36.2767 349.125 81.0262C349.125 125.776 312.848 162.052 268.099 162.052H81.0262C36.2767 162.052 0 125.776 0 81.0262Z"
                fill="url(#paint0_linear_0_1)" />
              <path
                d="M237.058 115.825C232.641 115.825 229.869 113.241 229.869 109.153C229.869 107.978 230.198 106.381 230.809 104.642L249.322 54.2243C251.295 48.7268 254.632 46.1895 259.988 46.1895C265.533 46.1895 268.822 48.6328 270.842 54.1773L289.449 104.642C290.107 106.475 290.342 107.743 290.342 109.153C290.342 113.053 287.382 115.825 283.247 115.825C279.253 115.825 277.139 113.993 275.87 109.576L272.299 98.8626H247.818L244.247 109.435C242.932 113.946 240.817 115.825 237.058 115.825ZM250.92 87.8675H269.01L260.035 60.0978H259.706L250.92 87.8675Z"
                fill="url(#paint1_linear_0_1)" />
              <path
                d="M204.295 115.825C199.831 115.825 197.199 113.1 197.199 108.448V58.8758H183.197C179.344 58.8758 176.76 56.6674 176.76 53.0024C176.76 49.3373 179.297 47.1289 183.197 47.1289H225.439C229.292 47.1289 231.829 49.3373 231.829 53.0024C231.829 56.6674 229.245 58.8758 225.439 58.8758H211.39V108.448C211.39 113.1 208.758 115.825 204.295 115.825Z"
                fill="url(#paint2_linear_0_1)" />
              <path
                d="M130.027 114.932C125.563 114.932 122.932 112.207 122.932 107.508V54.506C122.932 49.8542 125.563 47.1289 130.027 47.1289H162.26C166.16 47.1289 168.698 49.3373 168.698 53.0024C168.698 56.6674 166.113 58.8758 162.26 58.8758H137.122V75.1805H161.039C164.563 75.1805 166.959 77.248 166.959 80.6311C166.959 84.0142 164.61 86.0816 161.039 86.0816H137.122V103.185H162.26C166.113 103.185 168.698 105.394 168.698 109.059C168.698 112.724 166.16 114.932 162.26 114.932H130.027Z"
                fill="url(#paint3_linear_0_1)" />
              <path
                d="M65.5854 114.932C61.1215 114.932 58.4902 112.207 58.4902 107.508V54.506C58.4902 49.8542 61.1215 47.1289 65.5854 47.1289H87.9985C100.732 47.1289 108.72 53.6602 108.72 64.0444C108.72 71.4685 103.175 77.6709 95.9863 78.7516V79.1275C105.196 79.8323 111.962 86.5515 111.962 95.5261C111.962 107.461 102.987 114.932 88.5153 114.932H65.5854ZM72.6805 75.3215H82.8768C90.4418 75.3215 94.7647 71.9853 94.7647 66.2529C94.7647 60.8023 90.9587 57.7011 84.3334 57.7011H72.6805V75.3215ZM72.6805 104.36H84.8973C93.0731 104.36 97.4899 100.883 97.4899 94.3984C97.4899 88.0551 92.9322 84.672 84.5684 84.672H72.6805V104.36Z"
                fill="url(#paint4_linear_0_1)" />
              <defs>
                <linearGradient id="paint0_linear_0_1" x1="174.562" y1="0" x2="174.562" y2="162.052"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="white" />
                  <stop offset="1" stop-color="#DDDDDD" />
                </linearGradient>
                <linearGradient id="paint1_linear_0_1" x1="173.14" y1="23.3208" x2="173.14" y2="138.028"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#788B99" />
                  <stop offset="1" stop-color="#526478" />
                </linearGradient>
                <linearGradient id="paint2_linear_0_1" x1="173.147" y1="23.3205" x2="173.147" y2="138.027"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#788B99" />
                  <stop offset="1" stop-color="#526478" />
                </linearGradient>
                <linearGradient id="paint3_linear_0_1" x1="173.141" y1="23.3205" x2="173.141" y2="138.027"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#788B99" />
                  <stop offset="1" stop-color="#526478" />
                </linearGradient>
                <linearGradient id="paint4_linear_0_1" x1="173.14" y1="23.3205" x2="173.14" y2="138.027"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#788B99" />
                  <stop offset="1" stop-color="#526478" />
                </linearGradient>
              </defs>
            </svg>

          </div>

        </router-link>
      </div>
      <nav class="flex space-x-2 text-nowrap pl-4">
        <router-link to="/" class="text-white hover:text-blue-200 mx-2">Home</router-link>
        <router-link to="/about/general" class="text-white hover:text-blue-200 mx-2">About</router-link>

        <div v-if="!authState.isAuthenticated">
          <router-link to="/login" class="text-white font-bold hover:text-blue-200 mx-2">Log In</router-link>
        </div>
        <div v-else class="relative group">
          <router-link to="/account" class="text-white font-bold hover:text-blue-200 mx-2">{{ authState.username
            }}</router-link>
        </div>



      </nav>
    </div>
  </header>
</template>

<script>
import { authState, logout } from '../authStateManager.js';

export default {
  data() {
    return {
      authState, // Use the reactive global auth state
      logout,

    };
  },
};
</script>